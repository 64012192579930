import React from 'react';
import Swal from 'sweetalert2';
import { Lang } from '../libs/Language';
import { LANG } from '../libs/utils';
import "./Alert.css";


export const StandardAlert = (message) => {
    return (
        Swal.fire({
            title: message,
            width: 600,
        })
    )
}

export const SuccessAlert = (message, confirm) => {
    return (
        Swal.fire({
            position: "top-start",
            icon: "success",
            title: message,
            showConfirmButton: confirm ? true : false,
            timer: confirm ? "" : 1500,
        })
    )
}

export const ErrorAlert = (message) => {
    return (
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: message,
        })
    )
}

export const AttentionAlert = (message) => {
    return (
        Swal.fire({
            title: Lang("PERHATIAN", LANG, { en: `NOTIFICATION` }),
            width: 600,
            icon: "warning",
            text: message,
            allowOutsideClick: false,
            allowEscapeKey: false,
        })
    )
}

export const DecisionAlert = (title, confirm) => {
    return (
        Swal.fire({
            title: title,
            width: 600,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {

            if (result.isConfirmed && confirm) {
                Swal.fire({
                    icon: "success",
                    title: confirm,
                    showConfirmButton: false,
                    timer: 1500
                });
            }
            return result.isConfirmed;
        })
    )
}
