class AdviceWarnings {
    constructor() {
        this.adviceMessages = [
            "Fokuslah pada setiap pertanyaan dan ingat bahwa Anda telah melakukan persiapan dengan baik.",
            "Aturlah waktu dengan efektif, kerjakan soal satu per satu tanpa terburu-buru.",
            "Percayalah pada kemampuan Anda dan berikan yang terbaik dalam setiap jawaban.",
            "Gunakan teknik relaksasi sebelum mulai untuk membantu mempertahankan konsentrasi.",
            "Jika diperlukan, ambil jeda sejenak untuk menata pikiran Anda.",
            "Baca setiap pertanyaan dengan teliti sebelum memberikan jawaban.",
            "Mulailah dengan soal yang paling Anda kuasai untuk meningkatkan rasa percaya diri.",
            "Luangkan waktu untuk meninjau jawaban Anda sebelum melanjutkan ke pertanyaan berikutnya.",
            "Setiap langkah kecil membawa Anda lebih dekat ke tujuan, jadi nikmati prosesnya.",
            "Fokuslah pada proses menjawab, bukan hanya pada hasil yang ingin dicapai.",
            "Jangan terlalu khawatir tentang soal yang belum terjawab; konsentrasikan pada yang sedang Anda kerjakan.",
            "Manfaatkan strategi eliminasi untuk membantu menentukan jawaban yang tepat.",
            "Pastikan Anda merasa nyaman dan terhidrasi selama ujian berlangsung.",
            "Ingatlah, setiap ujian adalah kesempatan untuk belajar dan bertumbuh.",
            "Sikap positif dapat memberikan dorongan yang Anda butuhkan untuk berhasil.",
            "Catatlah ide-ide penting jika perlu, agar pikiran Anda tetap terorganisir.",
            "Luangkan waktu sejenak untuk bersantai di antara sesi ujian jika memungkinkan.",
            "Fokuslah pada hal-hal yang dapat Anda kendalikan selama ujian.",
            "Setiap jawaban yang Anda berikan adalah langkah menuju kesuksesan.",
            "Manfaatkan waktu ujian dengan sebaik mungkin dan hindari terburu-buru.",
            "Sediakan waktu untuk memeriksa jawaban Anda di akhir ujian.",
            "Jangan terlalu memikirkan jawaban yang sudah Anda berikan; konsentrasikan perhatian pada yang tersisa.",
            "Sikap optimis akan membantu Anda meraih hasil terbaik dalam ujian.",
            "Latihlah diri Anda dengan latihan soal untuk meningkatkan rasa percaya diri.",
            "Ingatlah bahwa Anda sudah mempersiapkan diri dengan baik sebelum ujian dimulai.",
            "Berikan usaha terbaik Anda, karena setiap upaya adalah langkah menuju kemajuan.",
            "Lihatlah setiap soal sebagai tantangan yang bisa Anda hadapi.",
            "Jangan biarkan pikiran negatif mengganggu; arahkan pikiran Anda pada hal-hal yang positif.",
            "Hargai setiap pertanyaan sebagai kesempatan untuk menunjukkan pengetahuan Anda.",
            "Selesaikan ujian dengan percaya diri, karena Anda telah bekerja keras untuk mempersiapkannya.",
            "Tetaplah fokus pada tujuan Anda dan jangan ragu untuk menunjukkan kemampuan.",
            "Cobalah untuk tetap terorganisir saat menjawab soal-soal ujian.",
            "Buatlah strategi untuk mengatur waktu Anda dengan lebih baik.",
            "Pikirkan tentang setiap soal sebagai kesempatan untuk belajar.",
            "Berikan diri Anda waktu untuk berpikir sebelum menjawab setiap pertanyaan.",
            "Bergantian antara membaca dan menjawab soal untuk menjaga konsentrasi.",
            "Berlatihlah menjawab soal dalam waktu yang ditentukan agar lebih siap.",
            "Kendalikan pikiran Anda dan jangan biarkan kekhawatiran menghalangi Anda.",
            "Ingatlah bahwa setiap langkah kecil adalah bagian dari proses belajar.",
            "Manfaatkan waktu Anda sebaik mungkin untuk meraih hasil terbaik.",
            "Jangan ragu untuk memikirkan kembali jawaban Anda jika perlu.",
            "Setiap usaha yang Anda lakukan akan membuahkan hasil di kemudian hari.",
            "Arahkan perhatian Anda pada hal-hal positif dan jangan biarkan hal negatif mengganggu.",
            "Ingatlah untuk tetap bernafas dan menjaga ketenangan saat mengerjakan soal.",
            "Simpan energi Anda untuk jawaban yang lebih sulit di bagian ujian selanjutnya.",
            "Luangkan waktu untuk memahami instruksi sebelum mulai mengerjakan soal.",
            "Jadwalkan waktu untuk beristirahat agar tetap segar selama ujian.",
            "Setiap jawaban adalah kesempatan untuk menunjukkan kemampuan Anda.",
            "Rencanakan pendekatan Anda terhadap setiap jenis soal.",
            "Ambil langkah kecil dan jangan terburu-buru dalam menjawab.",
            "Pastikan untuk mengingat kembali pelajaran yang telah Anda pelajari.",
            "Arahkan fokus Anda hanya pada satu soal pada satu waktu.",
            "Jika merasa bingung, kembali ke soal yang lebih mudah untuk membangun kembali kepercayaan diri.",
            "Tetaplah tenang dan ingat bahwa Anda sudah melakukan yang terbaik.",
            "Pertahankan pikiran positif dan visualisasikan kesuksesan Anda.",
            "Selalu periksa instruksi sebelum menjawab untuk menghindari kesalahan.",
            "Usahakan untuk tidak membandingkan diri Anda dengan peserta lain.",
            "Jaga konsentrasi dan jangan terlalu lama terjebak pada satu pertanyaan.",
            "Manfaatkan pengalaman sebelumnya sebagai referensi untuk menjawab soal.",
            "Kendalikan waktu Anda dengan baik dan tetapkan batas untuk setiap soal.",
            "Ingatlah bahwa proses belajar tidak pernah berhenti.",
            "Selalu siapkan diri untuk mengadaptasi jika ada perubahan dalam soal.",
            "Akan ada soal yang lebih mudah dan lebih sulit; hadapi semuanya dengan bijaksana.",
            "Berikan diri Anda pujian atas setiap kemajuan yang telah dicapai.",
            "Jangan biarkan kekhawatiran mengalihkan perhatian dari fokus Anda.",
            "Jika ada soal yang sulit, coba cari petunjuk dari pilihan jawaban.",
            "Manfaatkan semua sumber daya yang Anda miliki selama ujian.",
            "Berlatihlah dengan soal ujian sebelumnya untuk meningkatkan keterampilan Anda.",
            "Tetap berpegang pada strategi yang telah Anda rencanakan sebelumnya.",
            "Ambil setiap pertanyaan dengan serius dan tangani dengan cermat.",
            "Fokus pada aspek positif dari setiap tantangan yang Anda hadapi.",
            "Jangan ragu untuk beradaptasi jika pendekatan Anda tidak berjalan sesuai rencana.",
            "Selalu percaya pada kemampuan Anda untuk menemukan jawaban yang tepat.",
            "Setiap pengalaman adalah kesempatan untuk belajar dan tumbuh.",
            "Tantangan dalam ujian adalah bagian dari perjalanan menuju sukses."
        ];
        this.openTabWarnings = [
            "Membuka tab atau aplikasi lain dapat mengganggu fokus Anda. Harap kembali ke ujian.",
            "Tab atau aplikasi lain terdeteksi! Mohon kembali ke jendela ujian untuk melanjutkan.",
            "Menjaga fokus sangat penting. Silakan tutup tab atau aplikasi lain.",
            "Aktivitas di tab atau aplikasi lain dapat memengaruhi hasil ujian Anda.",
            "Jangan buka tab atau aplikasi lain. Tetap fokus pada ujian ini!",
            "Membuka tab atau aplikasi lain bisa mengganggu proses ujian. Harap kembali.",
            "Kami mendeteksi tab atau aplikasi lain. Kembali ke ujian untuk hasil terbaik.",
            "Fokus adalah kunci! Tutup tab atau aplikasi lain dan kembali ke ujian.",
            "Untuk menjaga integritas ujian, silakan tutup tab atau aplikasi lain.",
            "Membuka tab atau aplikasi lain dapat memengaruhi konsentrasi. Harap tutup.",
            "Jaga perhatian Anda pada ujian. Kembali ke jendela ini.",
            "Aktivitas di tab atau aplikasi lain tidak diperbolehkan selama ujian.",
            "Kembali ke ujian! Tab atau aplikasi lain dapat memengaruhi konsentrasi.",
            "Tab atau aplikasi lain terdeteksi! Silakan tutup untuk melanjutkan ujian.",
            "Fokuslah pada ujian! Tutup tab atau aplikasi lain untuk hasil terbaik.",
            "Mengalihkan perhatian dapat merugikan Anda. Kembali ke ujian.",
            "Kami menyarankan untuk tidak membuka tab atau aplikasi lain saat ujian.",
            "Tab atau aplikasi lain dapat menurunkan kualitas ujian Anda. Kembali ke sini.",
            "Jaga integritas ujian dengan menutup tab atau aplikasi lain.",
            "Fokus pada ujian sangat penting! Tutup tab atau aplikasi lain.",
            "Anda sedang ujian! Kembali ke jendela ini dan tutup yang lain.",
            "Aktifitas di luar ujian dapat mengganggu. Harap tutup tab atau aplikasi lain.",
            "Kembali ke ujian! Tab atau aplikasi lain dapat mengalihkan perhatian.",
            "Membuka tab atau aplikasi lain dapat memengaruhi hasil Anda. Harap tutup.",
            "Mohon fokus pada ujian ini dan tutup tab atau aplikasi lain.",
            "Kembali ke ujian! Tab atau aplikasi lain tidak diperbolehkan saat ini.",
            "Aktivitas di luar jendela ujian tidak diperbolehkan.",
            "Harap tetap di jendela ujian untuk hasil yang lebih baik.",
            "Anda berisiko kehilangan konsentrasi. Kembali ke ujian.",
            "Tutup tab atau aplikasi lain untuk menjaga fokus pada ujian ini.",
            "Silakan tutup tab atau aplikasi lain dan kembali untuk melanjutkan ujian.",
            "Kami mendeteksi tab atau aplikasi lain! Tetap fokus pada ujian Anda."
        ];
        this.faceDetectionMessages = [
            "Coba sesuaikan posisi Anda agar wajah terlihat lebih jelas.",
            "Sepertinya wajah Anda kurang terlihat. Pastikan menghadap kamera.",
            "Harap periksa apakah ada objek yang menghalangi kamera.",
            "Kami membutuhkan sedikit penyesuaian agar wajah Anda terlihat.",
            "Coba gerakkan sedikit agar wajah Anda terlihat lebih baik.",
            "Pastikan pencahayaan cukup dan wajah Anda menghadap kamera.",
            "Silakan periksa kembali posisi Anda agar wajah terlihat jelas.",
            "Coba pastikan tidak ada benda di depan kamera.",
            "Untuk hasil terbaik, pastikan wajah Anda terlihat jelas di kamera.",
            "Wajah mungkin terhalang. Pastikan tidak ada objek di depan.",
            "Silakan atur posisi agar wajah Anda terlihat lebih jelas.",
            "Coba pastikan wajah Anda tidak terlalu dekat atau jauh dari kamera.",
            "Perhatikan posisi Anda agar lebih terlihat.",
            "Pastikan wajah Anda berada dalam jangkauan kamera.",
            "Silakan periksa pencahayaan dan posisi wajah Anda.",
            "Harap pastikan wajah Anda terlihat jelas.",
            "Coba perbaiki posisi Anda sedikit agar lebih terlihat.",
            "Pastikan pencahayaan ruangan cukup agar wajah terlihat lebih baik.",
            "Silakan atur posisi dan pencahayaan agar wajah terlihat jelas.",
            "Silakan periksa apakah wajah Anda terlihat. Penyesuaian mungkin diperlukan.",
            "Coba sesuaikan posisi agar lebih terlihat.",
            "Pastikan wajah Anda dalam jangkauan kamera. Kami ingin membantu.",
            "Kami akan sangat menghargai jika wajah Anda terlihat jelas.",
            "Silakan periksa kembali posisi Anda untuk penyesuaian.",
            "Coba perbaiki pencahayaan agar wajah terlihat lebih jelas.",
            "Silakan atur posisi sedikit agar wajah terlihat.",
            "Kami ingin memastikan wajah Anda terlihat. Mohon sesuaikan posisi.",
            "Silakan perhatikan sudut kamera agar wajah terlihat jelas.",
            "Harap pastikan tidak ada yang menghalangi wajah Anda.",
            "Untuk kenyamanan Anda, pastikan wajah Anda terlihat jelas di depan kamera.",
            "Coba gerakkan sedikit agar wajah Anda terlihat.",
            "Pastikan wajah Anda tidak terlalu dekat atau jauh dari kamera. Sesuaikan posisi."
        ];
    }

    getFaceDetectionWarning() {
        const r_advice = Math.floor(Math.random() * this.adviceMessages.length);
        const r_face = Math.floor(Math.random() * this.faceDetectionMessages.length);
        return `${this.faceDetectionMessages[r_face]} ${this.adviceMessages[r_advice]}`;
    }
    
    getTabWarning() {
        const r_advice = Math.floor(Math.random() * this.adviceMessages.length);
        const r_tab = Math.floor(Math.random() * this.openTabWarnings.length);
        return `${this.openTabWarnings[r_tab]} ${this.adviceMessages[r_advice]}`
        
    }
}

export default AdviceWarnings;