import { getAccessToken, urlLocal } from "./utils";

let console = {};
console.log = function () { };

class FileWebsocket {
    constructor(isVideoFileWs, Id, type, folderRand, onMessageReceived = null, onClose = null) {
        this.Id = Id;
        this.type = type;
        this.folderRand = folderRand;
        this.socket = new WebSocket(`wss://${urlLocal}/${isVideoFileWs ? "/videofilews" : "/imagefilews"}`);

        // Connection opened
        this.socket.addEventListener('open', (event) => {
            let data = {
                'user-id': this.Id,
                'type': this.type,
                'folder-rand': this.folderRand
            }
            this.socket.send(
                JSON.stringify(data)
            );
        });

        // Listen for messages
        this.socket.addEventListener('message', (event) => {
            // console.log('[filews] Message from server ', event.data);
        });

        this.socket.onclose = () => {
            if (onClose !== null) {
                onClose(null)
            }
        }
        this.socket.onerror = (err) => {
            if (onClose !== null) {
                onClose(err);
            }
        }
        this.socket.onopen = () => {
        }

        this.socket.onmessage = (data) => {
            if (typeof (data.data) === "string") {
                if (onMessageReceived !== null) {
                    onMessageReceived(this._parent, data.data);
                }
            } else {
                data.data.text().then((d) => {
                    if (onMessageReceived !== null) {
                        onMessageReceived(this._parent, d);
                    }
                })
            }
        }
    }

    errorSocketIsNotReady() {
        // console.log("[commonws] socket is not ready");
    }

    close() {
        this.socket.close();
    }

    sendBlob(data) {
        if (this.socket.readyState === 1) {
            this.socket.send(data);
        } else {
            // console.log("socket is not ready yet");
        }
    }

    sendParticipantImageUnrecorded(id, base64, type = "face", is_warning = false, ts = null) {
        if (type === "screen") {
            return this.sendImage("participantscreenimgunrecorded", id, base64, is_warning, ts);
        }
        return this.sendImage("participantimgunrecorded", id, base64, is_warning, ts);
    }

    sendParticipantImage(id, base64, type = "face", is_warning = false, ts = null) {
        if (type === "screen") {
            return this.sendImage("participantscreenimg", id, base64, is_warning, ts);
        }
        return this.sendImage("participantimg", id, base64, is_warning, ts);
    }

    sendFaceScreenScreenShotImage(id, face_base64, screen_base64, notes, ts, folder_rand) {
        return this.sendScreenShotImage("screenshot", id, face_base64, screen_base64, notes, ts, folder_rand);
    }

    sendFaceInKTPImage(id, base64) {
        return this.sendImage("faceinktpimg", id, base64);
    }

    sendFaceInCamImage(id, base64) {
        return this.sendImage("faceincamimg", id, base64);
    }
    sendIdentityInCamImage(id, base64) {
        return this.sendImage("identityincamimg", id, base64);
    }

    sendImage(type, id, base64, is_warning = false, ts = null) {
        if (this.socket.readyState === 1) {
            this.socket.send(
                JSON.stringify(
                    {
                        type: type,
                        is_warning: is_warning,
                        profile: {
                            id: id,
                            img: base64,
                            'folder-rand': this.folderRand,
                            ts: ts
                        },
                        token: getAccessToken()
                    }));
        } else {
            this.errorSocketIsNotReady();
            return false;
        }
        return true;
    }

    sendScreenShotImage(type, id, face_base64, screen_base64, notes, ts, folder_rand) {
        if (this.socket.readyState === 1) {
            this.socket.send(
                JSON.stringify(
                    {
                        type: type,
                        profile: {
                            id: id,
                            img_face: face_base64,
                            img_screen: screen_base64,
                            notes: notes.replace(/\n/g, "\n"),
                            ts: ts,
                            'folder-rand': folder_rand
                        },
                        token: getAccessToken()
                    }));
        } else {
            this.errorSocketIsNotReady();

            return false;
        }
        return true;
    }

    sendProctorerImage(id, base64) {
        return this.sendImage("proctorimg", id, base64);
    }

    sendAIWarning2File(from, warning_type, ts, warningdata = null) {
        // AI Warning Type
        /*
        0 - no face detected
        1 - face detected more than 1
        2 - face id unmatch
        3 - open new tab
        */
        if (this.socket.readyState === 1) {
            this.socket.send(
                JSON.stringify(
                    {
                        type: "aiwarning2file",
                        from: from,
                        warningtype: warning_type,
                        ts: ts,
                        'folder-rand': this.folderRand,
                        warningdata: warningdata,
                        token: getAccessToken()
                    }
                )
            )
        } else {
            return false;
        }
        return true;
    }
}

export default FileWebsocket;
