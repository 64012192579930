import React from "react";
import classes from "./SystemBusy.module.scss";
import traffic from "../assets/imgs/traffic.png";

class SystemBusy extends React.Component {
    constructor(props) {
        super(props);
        this.messages = [
            "Hai kak, mohon maaf, ternyata peserta ujian hari ini banyak banget. Tapi tenang, waktu ujiannya masih panjang kok. Mohon bersabar ya sampai yang lain selesai dulu.",
            "Halo kak, maaf banget ya, peserta ujian kali ini ramai banget. Jangan khawatir, masih ada banyak waktu buat ujian. Silakan tunggu sebentar ya.",
            "Hai kak, peserta ujian hari ini lagi banyak-banyaknya. Tenang aja, waktu ujian masih cukup. Mohon sabar sebentar ya, tunggu yang lain menyelesaikan dulu.",
            "Halo kak, maaf nih, ujiannya lagi ramai banget hari ini. Tapi jangan khawatir, masih banyak waktu tersisa. Mohon tunggu sebentar ya sampai yang lain selesai.",
            "Hai kak, maaf ya, ujiannya lagi penuh banget peserta. Tapi nggak usah khawatir, waktu ujiannya masih panjang kok. Mohon tunggu dulu ya sambil nunggu yang lain selesai."
        ];
        this.messageId = Math.floor(Math.random() * this.messages.length)
    }

    render() {
        return <div className={classes.container}>
            <img alt="traffic" src={traffic} />
            {this.messages[this.messageId]}
        </div>
    }
}

export default SystemBusy;