import React from "react";
import classes from "./LoginPageMobile.module.scss";
import participant from "../../assets/imgs/student2.png";
import proctorer from "../../assets/imgs/proctorer2.png";
import { convertEpochTime, fetchDataJSON, formatTime } from "../../libs/utils";
import AuthContext from "../../store/auth-context";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  fetchCurrentTs, fetchExamProfile, fetchImageLength,
  fetchParticipantByParticipantId, fetchProctorerProfileById,
  fetchProctorerProfiles, urlFaceInKTPImage, urlWebCam, urlKTPImage
} from "../../libs/proctor_utils";
import showpassword from "../../assets/imgs/showpassword.png";
import hidepassword from "../../assets/imgs/hidepassword.png";
import { ErrorAlert } from "../../components/Alert";

let console = {};
console.log = function () { };

class LoginPageMobile extends React.Component {
  static contextType = AuthContext;
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      // loginAs: "", //"", "participant", "proctorer"
      loginAs: this.props.as, //"", "participant", "proctorer"
      isPageShown: true,
      ruleAsParticipant: true,

      showPassword: false,

      examProfile: {
        date: null,
        title: null,
        title_2: null,
        startTime: null,
        start_time_ts: null,
        preparationTime: null,
        preparation_time_ts: null,
        duration: null,
        durationTime: null
      },

      examTimes: {
        preparationTime: "00:00",
        startTime: "00:00",
        endTime: "00:00"
      },

      currentTimes: {
        date: "",
        hours: "",
        minutes: "",
        seconds: "",
      },
      currentTs: 0,

      preparationCountdown: {
        days: "",
        dates: "",
        hours: "",
        minutes: "",
        seconds: "",
      },

      examCountdown: {
        days: "",
        hours: "",
        minutes: "",
        seconds: "",
      },

      displayTimer: "",

      bgColor: '#E3B891',

    };
    this.usernameRef = React.createRef();
    this.passwordRef = React.createRef();
    this.containerRef = React.createRef();

    this.fetchCurrentTsInterval = null;
    this.clockInterval = null;

  }

  //set background berdasarkan ULR
  setBgColor() {
    const url = window.location.href;
    const hasSession = url.includes('pagi');

    if (hasSession) {
      this.setState({ bgColor: 'rgba(73, 147, 180, 0.7)' })
    }
  }

  displayLogo() {
    return (
      <div className={classes.container_card_t}>
        {/* <img alt="amanin" width="180px" src={amanin} /> */}
        <img alt="amanin" width="180px" src={this.state.examProfile.primary_logo_url} />
        {/* <span style={{ fontSize: "1.0rem" }}>{"PROKTOR"}</span>
        <span style={{ fontSize: "0.7rem" }}>{"by amanin"}</span> */}
      </div>
    );
  }

  displayDigitalClock() {
    return (
      <div className={classes.digital_clock}>
        <div className={classes.digital_clock_wrapper}>
          <div className={classes.days}>{this.state.currentTimes.date}</div>
          <div className={classes.time}>
            <div id="hours">{formatTime(this.state.currentTimes.hours)}</div>
            <div id="minutes">{formatTime(this.state.currentTimes.minutes)}</div>
            <div id="second"> {formatTime(this.state.currentTimes.seconds)}</div>
            {/* <div id="wilayah"> WIB</div> */}
          </div>
        </div>
      </div>
    )
  }


  loginPage() {
    return (
      <div className={classes.container_card}>
        {this.displayLogo()}
        <div className={classes.container_card_b}>
          <span
            className={classes.l}
            onClick={() => {
              this.gotoLoginAs("participant");
            }}
          >
            <img alt="participant" width="40px" src={participant} />
            Login Sebagai Peserta
          </span>
          <span
            className={classes.r}
            onClick={() => {
              this.gotoLoginAs("proctorer");
            }}
          >
            <img alt="proctorer" width="40px" src={proctorer} />
            Login Sebagai Pengawas
          </span>
        </div>
      </div>
    );
  }

  logingIn() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      action: "login",
      body: {
        username: this.usernameRef.current.value,
        password: this.passwordRef.current.value,
        as: this.state.loginAs,
      },
    };
    let url = "/api/proktor/login";
    fetchDataJSON(url, requestOptions, (error) => {
      console.log("error=", error);
    }).then((data) => {
      if (data === undefined || data === null) {
        ErrorAlert("Username dan Password tidak cocok.");
        return;
      }
      if (data.status === "success") {
        const expiresIn = 3600 * 12;
        const expirationTime = new Date(
          new Date().getTime() + expiresIn * 1000
        );
        this.context.login(
          "idToken",
          this.usernameRef.current.value,
          data.result,
          "lang",
          expirationTime
        );
        const { history } = this.props;

        if (this.state.loginAs === "participant") {
          history.replace("/exam");
        } else if (this.state.loginAs === "proctorer") {
          history.replace("/proctor");
        }
      } else {
        ErrorAlert(data.errorMessage);
      }

    });
  }

  loginAs(title) {

    // let title = "Login Sebagai Peserta";
    // if (this.state.loginAs === "proctorer") {
    //   title = "Login Sebagai Pengawas";
    // }
    return (
      <div className={[classes.container_card].join(" ")}>
        {this.displayLogo()}
        <div className={classes.container_card_b}>
          <div className={classes.form}>
            <div className={classes.form_title}>{title}</div>
            <div className={classes.form_f}>
              <div className={classes.form_f_l}>
                <div>Username</div>
                <div>Password</div>
              </div>
              <div className={classes.form_f_r}>
                <div>
                  <input
                    type={"text"}
                    name={"username"}
                    ref={this.usernameRef}
                    required
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        this.logingIn();
                      }
                    }}
                  />
                </div>
                <div>
                  <input
                    type={this.state.showPassword === true ? "text" : "password"}
                    name={"password"}
                    ref={this.passwordRef}
                    required
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        this.logingIn();
                      }
                    }}
                  />
                  <img
                    onClick={() => {
                      this.setState({
                        showPassword: !this.state.showPassword
                      })
                    }}
                    // onMouseDown={(e) => {
                    //   console.log("down");
                    //   this.setState({
                    //     showPassword: true
                    //   })
                    // }}
                    // onMouseUp={(e) => {
                    //   console.log("up");
                    //   this.setState({
                    //     showPassword: false
                    //   })
                    // }}
                    alt="pass" src={this.state.showPassword === false ? showpassword : hidepassword} height="30px" style={{
                      marginLeft: "2px",
                      marginRight: "10px"
                    }} />
                </div>
              </div>
            </div>
            <div className={classes.form_f_b}>
              <div
                // className={classes.c}
                onClick={() => {
                  this.gotoLoginAs("");
                }}
              >
                {/* Cancel */}
              </div>
              <div
                className={[classes.btnOk].join(" ")}
                style={{
                  marginRight: "20px",
                  padding: "8px 20px 8px 20px"
                }}
                onClick={() => {
                  this.logingIn();
                }}
              >
                Login
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  gotoLoginAs(loginAs) {
    this.setState({ isPageShown: false });
    setTimeout(() => {
      this.setState({
        loginAs: loginAs,
        // isPageShown: true
      });
      setTimeout(() => {
        this.setState({
          isPageShown: true,
        });
      }, 100);
    }, 500);
  }

  loginAsProctorer() {
    return this.loginAs("Login sebagai Pengawas");
  }
  loginAsParticipant() {
    // return this.loginAs("Login sebagai Peserta");
    return this.loginAs(`Silahkan Login Menggunakan Akun dan Password Pendaftaran Anda`);
  }

  componentWillUnmount() {
    clearInterval(this.fetchCurrentTsInterval);
    clearInterval(this.clockInterval);

  }

  componentDidMount() {
    this.setBgColor();

    fetchCurrentTs((error) => { }).then((data) => {
      this.setState({ currentTs: data.result })
    })

    clearInterval(this.fetchCurrentTsInterval);
    this.fetchCurrentTsInterval = setInterval(() => {
      fetchCurrentTs((error) => { }).then((data) => {
        if (data !== null) {
          this.setState({ currentTs: data.result })
        }
      })
    }, 60000)

    clearInterval(this.clockInterval)
    this.clockInterval = setInterval(() => {
      if (this.state.currentTs === 0) {
        return;
      }
      const date = new Date(this.state.currentTs);
      let ct = this.state.currentTimes;
      ct = {
        date: date.toLocaleDateString("id", { weekday: "long" }),
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: date.getSeconds(),
      }

      // const countDown = new Date(this.state.examProfile?.preparation_time_ts - data.result);
      const countDown = (this.state?.examProfile?.preparation_time_ts * 1000) - this.state.currentTs;
      // convert milliseconds to seconds, minutes, hours, and days
      const datesP = new Date(this.state?.examProfile?.preparation_time_ts * 1000)
      const daysP = Math.floor(countDown / (1000 * 60 * 60 * 24))
      const secondsP = Math.floor((countDown / 1000) % 60);
      const minutesP = Math.floor((countDown / 1000 / 60) % 60);
      const hoursP = Math.floor((countDown / (1000 * 60 * 60)) % 24);

      const prepareTimer = {
        dates: `${formatTime(datesP.getDate())} ${datesP.toLocaleString('default', { month: 'short' })} ${datesP.getFullYear()}`,
        days: daysP,
        hours: hoursP > 0 ? hoursP : 0,
        minutes: minutesP > 0 ? minutesP : 0,
        seconds: secondsP > 0 ? secondsP : 0,
      }

      const examCountdown = (this.state?.examProfile?.start_time_ts * 1000) - this.state.currentTs;

      const daysE = Math.floor(examCountdown / (1000 * 60 * 60 * 24))
      const hoursE = Math.floor((examCountdown / (1000 * 60 * 60)) % 24);
      const minutesE = Math.floor((examCountdown / 1000 / 60) % 60);
      const secondsE = Math.floor((examCountdown / 1000) % 60);

      const examTimer = {
        days: daysE,
        hours: hoursE > 0 ? hoursE : 0,
        minutes: minutesE > 0 ? minutesE : 0,
        seconds: secondsE > 0 ? secondsE : 0,
      }

      this.setState({
        currentTimes: ct,
        currentTs: this.state.currentTs + 1000,
        preparationCountdown: prepareTimer,
        examCountdown: examTimer
      });

      if ((this.state.examProfile.preparation_time_ts * 1000) > this.state.currentTs) {
        this.setState({ displayTimer: "persiapan ujian" })
      } else if ((this.state.examProfile.start_time_ts * 1000) > this.state.currentTs) {
        this.setState({ displayTimer: "waktu ujian" })
      } else if ((this.state.examProfile.end_time_ts * 1000) > this.state.currentTs) {
        this.setState({ displayTimer: "ujian" })
      } else {
        this.setState({ displayTimer: "selesai" })
      }

    }, 1000)

    // fetchExamProfile
    fetchExamProfile((error) => {
      console.log("error fetch exam", error);
    }).then((data) => {
      if (data !== null) {
        data = data.result;
        let ep = this.state.examProfile;
        ep = {
          date: data.date,
          title: data.title,
          title_2: data["title-2"],
          startTime: data.start_time,
          start_time_ts: data.start_time_ts,
          preparationTime: data.preparation,
          preparation_time_ts: data.preparation_time_ts,
          duration: data.duration,
          end_time_ts: data.end_time_ts,
          primary_logo_url: data.primary_logo_url,
          secondary_logo_url: data.secondary_logo_url
        }

        document.title = ep.title;

        const preparationTime = `${formatTime(convertEpochTime(data.preparation_time_ts).getHours())}:${formatTime(convertEpochTime(data.preparation_time_ts).getMinutes())}`;

        const startTime = `${formatTime(convertEpochTime(data.start_time_ts).getHours())}:${formatTime(convertEpochTime(data.start_time_ts).getMinutes())}`;

        const endTime = `${formatTime(convertEpochTime(data.end_time_ts).getHours())}:${formatTime(convertEpochTime(data.end_time_ts).getMinutes())}`;

        let et = this.state.examTimes;
        et = {
          preparationTime: preparationTime,
          startTime: startTime,
          endTime: endTime,
        }
        this.setState({ examProfile: ep, examTimes: et });
      } else {
        ErrorAlert("Error to fetch exam profile");
      }
    })


  }



  printDetail(date, time, text, content, contentSec) {
    return <div className={classes.detail}>
      {/* <div className={classes.detail_time}> */}
      <>
        {date}
        {/* <br /> */}
        {time}
      </>
      {/* <>
          {time}
        </>
        <br />
        <>
          {date}

        </> */}
      {/* {`${date}\n
        ${time}`} */}
      {/* </div> */}
      <div className={classes.detail_text}>
        <span style={{ fontWeight: "bold", padding: "3px 3px 3px 0px" }}>{text}</span>
        <span>{content}</span>
        <span>{contentSec}</span>
      </div>
    </div>
  }

  newPrintDetail(color) {
    return <div className={classes.detail}>
      <div className={classes.detail_text}>
        <div className={classes.detail_top} style={{ backgroundColor: color }}>
          <span style={{ fontWeight: "bold", }}>Informasi Detail Ujian Seleksi Mandiri dapat didapatkan di Admission ITB</span>
        </div>
        <div className={classes.detail_bottom}>
          <span>Link: </span>
          <a href='https://admission.itb.ac.id/home/sarjana/mandiri'>  https://admission.itb.ac.id/home/sarjana/mandiri</a>
        </div>
      </div>
    </div>
  }

  renderRules() {
    return <div className={classes.rules}>
      {
        this.state.loginAs === "participant"
          ?
          <div className={classes.chosen}>Peserta</div>
          :
          <div className={classes.chosen}>Pengawas</div>
      }
    </div>


    // return <div className={classes.rules}>
    //   <div className={this.state.ruleAsParticipant === true ? classes.chosen : null}
    //     onClick={() => {
    //       this.setState({ ruleAsParticipant: !this.state.ruleAsParticipant })
    //     }}
    //   >Peserta</div>
    //   <div className={this.state.ruleAsParticipant === false ? classes.chosen : null}
    //     onClick={() => {
    //       this.setState({ ruleAsParticipant: !this.state.ruleAsParticipant })
    //     }}
    //   >Pengawas</div>
    // </div>
  }

  preparationCountdown() {
    return <div className={classes.preparation_countDown}>
      <div className={classes.preparation_countDown_text}>
        <span style={{
          fontSize: ".75rem",
          fontWeight: "bold",
          padding: "3px 3px 3px 0px"
        }}>
          Menuju Persiapan Ujian
        </span>
      </div>
      <div className={classes.preparation_countDown_timer}>

        <div className={classes.time}>
          <div id="days">{this.state.preparationCountdown.days}</div>
          <div id="hours">{formatTime(this.state.preparationCountdown.hours)}</div>
          <div id="minutes">{formatTime(this.state.preparationCountdown.minutes)}</div>
          <div id="second"> {formatTime(this.state.preparationCountdown.seconds)}</div>
        </div>
        <div className={classes.info}>
          <div id="days">Hari</div>
          <div id="hours">Jam</div>
          <div id="minutes">Menit</div>
          <div id="second"> Detik</div>
        </div>

      </div>
    </div>
  }

  examCountdown() {
    return <div className={classes.preparation_countDown}>
      <div className={classes.preparation_countDown_text}>
        <span style={{
          fontSize: ".75rem",
          fontWeight: "bold",
          padding: "3px 3px 3px 0px"
        }}>
          Menuju Waktu Ujian
        </span>
      </div>
      <div className={classes.preparation_countDown_timer}>

        <div className={classes.time}>
          {/* <div id="days">2</div> */}
          <div id="days">{this.state?.examCountdown?.days}</div>
          <div id="hours">{formatTime(this.state?.examCountdown?.hours)}</div>
          <div id="minutes">{formatTime(this.state?.examCountdown?.minutes)}</div>
          <div id="second"> {formatTime(this.state?.examCountdown?.seconds)}</div>
        </div>
        <div className={classes.info}>
          <div id="days">Hari</div>
          <div id="hours">Jam</div>
          <div id="minutes">Menit</div>
          <div id="second"> Detik</div>
        </div>

      </div>
    </div>
  }

  onExam() {
    let result = "";

    if (this.state.displayTimer === "ujian") {
      result = "Ujian Sedang Berlangsung"
    } else if (this.state.displayTimer === "selesai") {
      result = "Ujian Telah Selesai"
    }

    return <div className={classes.preparation_countDown}>
      <div className={classes.preparation_countDown_text}>
      </div>
      <div className={classes.preparation_countDown_timer}>
        <h6 style={{ fontWeight: "bold" }}>
          {result}
        </h6>
      </div>
    </div>
  }

  onCountDown = () => {
    const { displayTimer } = this.state;
    if (displayTimer === "persiapan ujian") {
      return this.preparationCountdown();
    } else if (displayTimer === "waktu ujian") {
      return this.examCountdown();
    } else if (displayTimer === "ujian" || displayTimer === "selesai") {
      return this.onExam();
    }
  }


  proctoring_management() {
    return <div></div>;
    return <div className={classes.proctoring_management_text}>
      <a href="/management">Proctoring Management</a>
    </div>
  }

  renderProctorersRule() {
    return <div className={classes.container_t_d_d}>
      {this.newPrintDetail('rgba(227, 184, 145, 0.25)')}
    </div>;
  }

  renderParticipantsRule() {
    return <div className={classes.container_t_d_d}>
      {this.newPrintDetail('rgba(0, 221, 255, 0.25)')}
    </div>;
  }

  // renderProctorersRule() {
  //   return <div className={classes.container_t_d_d}>
  //     {this.printDetail(this.state.examProfile?.date, `08:00 WIB`, `Persiapan`, "Ruang Ujian dibuka, Para Peserta Akan Login ke Aplikasi ujian dan Validasi Wajah Peserta.")}
  //     {this.printDetail(this.state.examProfile?.date, `08:45 WIB`, `Validasi Selesai`, "Validasi Wajah Peserta selesai.")}
  //     {this.printDetail(this.state.examProfile?.date, `09:00 WIB`, "Ujian Mulai",
  //       "Ujian Mata Uji Matematika")}
  //     {this.printDetail(this.state.examProfile?.date, `10:40 WIB`, "Ujian Mata Uji Matematika Selesai",
  //       `Selesai Mata Uji Matematika,`, `Peserta yang tidak mengikuti mata uji Fisika dapat meninggalkan ruang ujian `)}
  //     {this.printDetail(this.state.examProfile?.date, `10:45 WIB`, "Ujian Mulai",
  //       "Ujian Mata Uji Fisika (mekanika dan gelombang)")}
  //     {this.printDetail(this.state.examProfile?.date, `${this.state.examTimes.startTime} WIB`, "Ujian Mulai",
  //       "Ujian mulai dilakukan. Para peserta ujian diharapkan mengikuti " +
  //       "ujian dengan jujur, serta mengikuti aturan yang telah ditetapkan. Selama ujian berlangsung, pengawasan akan dilakukan oleh " +
  //       "pengawas ujian dan AI.")}
  //     {this.printDetail(this.state.examProfile?.date, `12:10 WIB`, "Ujian Mata Uji Fisika (mekanika dan gelombang) Selesai",
  //       "Ujian Selesai.")}
  //   </div>;
  // }

  // renderProctorersRule() {
  //   return <div className={classes.container_t_d_d}>
  //     {this.printDetail(this.state.preparationCountdown.dates, `${this.state.examTimes.preparationTime} WIB`, `Persiapan`, "Pengawas dapat melakukan Login.")}
  //     {this.printDetail(this.state.examProfile?.date, `${this.state.examTimes.startTime} WIB`, "Ujian Mulai",
  //       "Ujian mulai dilakukan. Pengawas bisa melakukan pengawasan selama ujian berlangsung demgan dibantu oleh AI." +
  //       "Apabila diperlukan, pengawas dapat pula memberikan peringatan ke peserta ataupun broadcast di grup yang sedang diawasi.")}
  //     {this.printDetail(this.state.examProfile?.date, `${this.state.examTimes.endTime} WIB`, "Ujian Selesai",
  //       "Ujian dinyatakan selesai. Pengawas dapat melakukan review pengawasan kembali dengan cara mengakses laman proctoring management.")}
  //   </div>;
  // }

  // renderParticipantsRule() {
  //   return <div className={classes.container_t_d_d}>
  //     {this.printDetail(this.state.preparationCountdown.dates, `${this.state.examTimes.preparationTime} WIB`, `Persiapan`, "Peserta dipersilakan login, untuk melakukan registrasi.")}
  //     {/* {this.printDetail(this.state.preparationCountdown.dates, `${this.state.examTimes.preparationTime} WIB`, `Persiapan`, "Peserta dipersilakan login, untuk melakukan registrasi. Peserta yang sudah login adalah peserta yang sudah finalisasi tanggal ... dan jam sesuai ...")} */}
  //     {this.printDetail(this.state.examProfile?.date, `${this.state.examTimes.startTime} WIB`, "Ujian Mulai",
  //       "Ujian mulai dilakukan. Para peserta ujian diharapkan mengikuti " +
  //       "ujian dengan jujur, serta mengikuti aturan yang telah ditetapkan. Selama ujian berlangsung, pengawasan akan dilakukan oleh " +
  //       "pengawas ujian dan AI.")}
  //     {this.printDetail(this.state.examProfile?.date, `${this.state.examTimes.endTime} WIB`, "Ujian Selesai",
  //       "Ujian dinyatakan selesai. Para peserta akan logout secara otomatis dan tidak " +
  //       "dapat login kembali.")}
  //   </div>;
  // }

  // renderParticipantsRule() {
  //   return <div className={classes.container_t_d_d}>
  //     {this.printDetail(this.state.examProfile?.date, `08:00 WIB`, `Persiapan`, "Ruang Ujian dibuka, Login ke Aplikasi ujian dan Validasi Wajah Peserta.")}
  //     {this.printDetail(this.state.examProfile?.date, `08:45 WIB`, `Validasi Selesai`, "Validasi Wajah Peserta selesai.")}
  //     {this.printDetail(this.state.examProfile?.date, `09:00 WIB`, "Ujian Mulai",
  //       "Ujian Mata Uji Matematika")}
  //     {this.printDetail(this.state.examProfile?.date, `10:40 WIB`, "Ujian Mata Uji Matematika Selesai",
  //       `Selesai Mata Uji Matematika,`, `Peserta yang tidak mengikuti mata uji Fisika dapat meninggalkan ruang ujian `)}
  //     {this.printDetail(this.state.examProfile?.date, `10:45 WIB`, "Ujian Mulai",
  //       "Ujian Mata Uji Fisika (mekanika dan gelombang)")}
  //     {/* {this.printDetail(this.state.examProfile?.date, `${this.state.examTimes.startTime} WIB`, "Ujian Mulai",
  //       "Ujian mulai dilakukan. Para peserta ujian diharapkan mengikuti " +
  //       "ujian dengan jujur, serta mengikuti aturan yang telah ditetapkan. Selama ujian berlangsung, pengawasan akan dilakukan oleh " +
  //       "pengawas ujian dan AI.")} */}
  //     {this.printDetail(this.state.examProfile?.date, `12:10 WIB`, "Ujian Mata Uji Fisika (mekanika dan gelombang) Selesai",
  //       "Ujian Selesai.")}
  //   </div>;
  // }

  apiCallback = (data) => {
    console.log(data);
  }

  errorCallback = (error) => {
    console.log("error = ", error);
  }

  generateAPITester() {
    return <>
      <div>Test API</div>
      <div>
        <button onClick={
          () => {
            fetchExamProfile(this.errorCallback).then(this.apiCallback)
          }
        }>fetchExamProfile</button>
      </div>
      <div>
        <button onClick={
          () => {
            fetchImageLength("1001", "1679292746442", this.errorCallback).then(this.apiCallback)
          }
        }>fetchImageLength</button>
      </div>
      <div>
        <button onClick={
          () => {
            fetchProctorerProfiles(this.errorCallback).then(this.apiCallback)
          }
        }>fetchProctorerProfiles</button>
      </div>
      <div>
        <button onClick={
          () => {
            fetchProctorerProfileById("proctor001", this.errorCallback).then(this.apiCallback)
          }
        }>fetchProctorerProfileById</button>
      </div>
      <div>
        <button onClick={
          () => {
            fetchCurrentTs(this.errorCallback).then(this.apiCallback)
          }
        }>fetchCurrentTs</button>
      </div>
      <div>
        <button onClick={
          () => {
            fetchParticipantByParticipantId("1002", this.errorCallback).then(this.apiCallback)
          }
        }>fetchParticipantByParticipantId</button>
      </div>
      <img alt="ktp" src={urlKTPImage("1001")} width="320px" />
      <img alt="faceinktp" src={urlFaceInKTPImage("1001")} width="80px" />
      <img alt="faceincam" src={urlWebCam("1001")} width="80px" />
    </>
  }

  generateLoginPage() {
    const amaninColor = '#82c9ec';

    //Sesi Pagi
    const secondaryColor = '#E3B891'; // Krem

    //Sesi Siang
    const tertiaryColor = "rgba(73, 147, 180, 0.7)"; // blue

    // const tertiaryColor = '#4993B4'; // Yellow
    // const tertiaryColor = '#59EDDE'; // blue

    const backGround = this.state.loginAs === "participant" ? amaninColor : secondaryColor;
    // const backGround = this.state.loginAs === "participant" ? amaninColor : tertiaryColor;

    // const backGround = this.state.loginAs === "participant" ? amaninColor : this.state.bgColor;

    return (
      <div ref={this.containerRef} style={{ backgroundColor: backGround }} className={[classes.container].join(" ")}>

        <div className={classes.container_t}>
          <div className={classes.container_t_t}>
            <span className={classes.header}>
              {this.state.examProfile?.title}
            </span>
            <span className={classes.sub}>
              {this.state.examProfile?.title_2}
            </span>
            <span className={classes.sub2}>
              Diselenggarakan pada tanggal {this.state.examProfile?.date}
            </span>
            {this.displayDigitalClock()}
          </div>
          <div className={classes.container_t_d}>
            <div>
              {/* <div className={classes.container_c} >*/}

              {this.state.loginAs === "participant"
                ? this.loginAsParticipant()
                : this.loginAsProctorer()}
              {/* </div> */}

              {this.proctoring_management()}

            </div>

            <div className={classes.divider}>
              {/* {this.renderRules()} */}
              {/*
              {this.state.loginAs === "participant"
              ? this.renderParticipantsRule()
              : this.renderProctorersRule()}
            */}
              {/* {this.preparationCountdown()} */}
              {this.onCountDown()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  // generateChat() {
  //   return <div>
  //     <ChatXMPP domain={"console.amanin.id"} resource={"itb"} username={"amanin"} password={"amanin123"}/>
  //     <ChatXMPP domain={"console.amanin.id"} resource={"itb"} username={"amanin2"} password={"amanin123"}/>
  //     </div>
  // }

  render() {
    if (this.state.examProfile.title === null) {
      return <div style={{ display: "flex", width: "100%", justifyContent: "center" }}></div>;
    }

    return (this.generateLoginPage()
    )
    // return (
    //   <div className={[classes.container].join(" ")}>
    //     {this.displayDigitalClock()}
    //     <div className={classes.container_t}>
    //       <div className={classes.container_t_t}>
    //         <span className={classes.header}>
    //           {this.state.examProfile?.title}
    //         </span>
    //         <span className={classes.sub}>
    //           {this.state.examProfile?.title_2}
    //         </span>
    //         <span className={classes.sub2}>
    //           Diselenggarakan pada tanggal {this.state.examProfile?.date}
    //         </span>
    //       </div>
    //       <div className={classes.container_t_d}>
    //         <div className={classes.divider}>
    //           {this.renderRules()}
    //           {this.state.ruleAsParticipant ? this.renderParticipantsRule() : this.renderProctorersRule()}

    //           {/* {this.preparationCountdown()} */}
    //           {this.onCountDown()}

    //         </div>
    //         {/* <div className={classes.divider}>
    //           {this.displayDigitalClock()}
    //         </div> */}
    //         <div className={classes.divider}>
    //           <Transition
    //             nodeRef={this.camRef}
    //             in={this.state.isPageShown}
    //             timeout={500}
    //             mountOnEnter
    //             unmountOnExit
    //           >

    //             {(state) => {
    //               return (
    //                 <div
    //                   className={[
    //                     classes.container_c,
    //                     state === "entering"
    //                       ? classes.FadeInPage
    //                       : state === "exiting"
    //                         ? classes.FadeOutPage
    //                         : null,
    //                   ].join(" ")}
    //                 >
    //                   {this.state.loginAs === ""
    //                     ? this.loginPage()
    //                     : this.state.loginAs === "participant"
    //                       ? this.loginAsParticipant()
    //                       : this.loginAsProctorer()}
    //                 </div>
    //               );
    //             }}
    //           </Transition>

    //           {this.proctoring_management()}

    //         </div>
    //       </div>
    //     </div>

    //     {/* <div className={classes.container_api}>
    //       {this.generateAPITester()}
    //     </div> */}

    //     {/* <canvas name="canvas"></canvas> */}
    //     {/* <div style={{
    //       width: "1000px",
    //       height: "1000px",
    //       border: "2px solid red",
    //       backgroundColor: "black",
    //       position: "fixed"
    //     }}>
    //       <iframe src="https://edunex.itb.ac.id/" width="100%" height="100%" title="moodle"></iframe>
    //     </div> */}
    //   </div>
    // );
  }
}

const LoginPageRouter = withRouter(LoginPageMobile);
export default LoginPageRouter;
