import { ErrorAlert } from "../../components/Alert";
import ChromeExtension from "../../libs/ChromeExtension";
import { fetchExamProfile, fetchLoginGeneral, fetchDirectUserLogin, fetchExamProfileInitial } from "../../libs/proctor_utils";
import { convertEpochTime, formatTime, telkomPageURL, telkomProktorURL } from "../../libs/utils";

let console = {};
console.log = function () { };
console.error = function () { };

export const __Action__ = function (_this) {
    _this.CE = new ChromeExtension();

    this.checkExtensionRoutine = () => {
        if (_this.state.examProfile.useExtension) {
            _this.CE.checkExtension((result) => {
                _this.setState({ extensionInstalled: result })
            });
        }
    }

    this.clockRoutine = () => {
        if (_this.state.examProfile.useExtension) {
            this.checkExtensionRoutine();
        }

        if (_this.state.currentTs === 0) {
            // console.log("current ts 0");
            return;
        }

        const date = new Date(_this.state.currentTs);
        // let ct = _this.state.currentTimes;
        let ct = {
            date: date.toLocaleDateString("id", { weekday: "long" }),
            hours: date.getHours(),
            minutes: date.getMinutes(),
            seconds: date.getSeconds(),
        }

        // const countDown = new Date(_this.state.examProfile?.preparation_time_ts - data.result);
        const countDown = (_this.state?.examProfile?.preparation_time_ts * 1000) - _this.state.currentTs;
        // convert milliseconds to seconds, minutes, hours, and days
        const datesP = new Date(_this.state?.examProfile?.preparation_time_ts * 1000)
        const daysP = Math.floor(countDown / (1000 * 60 * 60 * 24))
        const secondsP = Math.floor((countDown / 1000) % 60);
        const minutesP = Math.floor((countDown / 1000 / 60) % 60);
        const hoursP = Math.floor((countDown / (1000 * 60 * 60)) % 24);

        const prepareTimer = {
            dates: `${formatTime(datesP.getDate())} ${datesP.toLocaleString('default', { month: 'short' })} ${datesP.getFullYear()}`,
            days: daysP,
            hours: hoursP > 0 ? hoursP : 0,
            minutes: minutesP > 0 ? minutesP : 0,
            seconds: secondsP > 0 ? secondsP : 0,
        }

        const examCountdown = (_this.state?.examProfile?.start_time_ts * 1000) - _this.state.currentTs;

        const daysE = Math.floor(examCountdown / (1000 * 60 * 60 * 24))
        const hoursE = Math.floor((examCountdown / (1000 * 60 * 60)) % 24);
        const minutesE = Math.floor((examCountdown / 1000 / 60) % 60);
        const secondsE = Math.floor((examCountdown / 1000) % 60);

        const examTimer = {
            days: daysE,
            hours: hoursE > 0 ? hoursE : 0,
            minutes: minutesE > 0 ? minutesE : 0,
            seconds: secondsE > 0 ? secondsE : 0,
        }

        _this.setState({
            currentTimes: ct,
            currentTs: _this.state.currentTs + 1000,
            preparationCountdown: prepareTimer,
            examCountdown: examTimer
        });

        if ((_this.state.examProfile.preparation_time_ts * 1000) > _this.state.currentTs) {
            _this.setState({ displayTimer: "persiapan ujian" })
        } else if ((_this.state.examProfile.start_time_ts * 1000) > _this.state.currentTs) {
            _this.setState({ displayTimer: "waktu ujian" })
        } else if ((_this.state.examProfile.end_time_ts * 1000) > _this.state.currentTs) {
            _this.setState({ displayTimer: "ujian" })
        } else {
            _this.setState({ displayTimer: "selesai" })
        }
    }

    this.redirectOnLogin = (result = { as: "participant" }) => {
        const { history } = _this.props;
        if (result.as === "participant") {
            history.replace("/exam");
        } else if (result.as === "proctorer") {
            history.replace("/proctor");
        }
    }

    this.onlineDetectedRetryInterval = null;
    this.onlineDetectedRetryHandler = (data, username, password, pperp) => {
        if (data.error && data.error.code === 109) {
            _this.setState({
                onlineDetectedRetry: {
                    username: username,
                    password: password,
                    wait: data.error.wait + 5,
                    progress: 0,
                    pperp: pperp,
                    retry: 1,
                }
            })
            clearInterval(this.onlineDetectedRetryInterval);
            this.onlineDetectedRetryInterval = setInterval(() => {
                if (_this.state.onlineDetectedRetry.progress >= _this.state.onlineDetectedRetry.wait) {
                    clearInterval(this.onlineDetectedRetryInterval)
                    setTimeout(() => {
                        this.loggingIn(
                            _this.state.onlineDetectedRetry.username,
                            _this.state.onlineDetectedRetry.password
                        );
                        _this.setState(prevState => ({
                            onlineDetectedRetry: {
                                ...prevState.onlineDetectedRetry,
                                retry: 2
                            }
                        }))
                    }, 1000)
                }

                _this.setState(prevState => ({
                    onlineDetectedRetry: {
                        ...prevState.onlineDetectedRetry,
                        progress: prevState.onlineDetectedRetry.progress + (1 / 4)
                    }
                }))
            }, 1000 / 4)
        }
    }
    this.loggingInHandler = (data, username, password, pperp) => {
        _this.setState({ loadingMessage: null })
        if (data === undefined || data === null) {
            ErrorAlert("Username dan Password tidak cocok.");
            return;
        }

        if (data.status === "success") {
            const expiresIn = 3600 * 12;
            const expirationTime = new Date(
                new Date().getTime() + expiresIn * 1000
            );

            const result = data.result;
            _this.context.login(
                result.as,
                result.access_token,
                username,
                result,
                "lang",
                expirationTime
            );

            // Simpan username dan password di session
            sessionStorage.setItem("directUser", JSON.stringify({ username, password }));

            if (result.as !== "proctorer" && result.terms_and_conditions !== 1 && _this.state.examProfile.hide_terms_conditions === 0) {
                _this.setState({ showTerms: true })
            } else {
                this.redirectOnLogin(result);
            }
        } else if (!_this.state.onlineDetectedRetry && data.error) {
            this.onlineDetectedRetryHandler(data, username, password, pperp)
        } else {
            ErrorAlert(data.errorMessage);
            _this.setState({ onlineDetectedRetry: null })
        }
    }
    this.loggingIn = (uname = null, upass = null) => {
        _this.setState({ loadingMessage: "Logging In" })
        let username, password;
        if (uname && upass) {
            username = uname
            password = upass
        } else {
            username = _this.usernameRef.current.value;
            password = _this.passwordRef.current.value;
        }
        const pperp = _this.state.examProfile.peserta_per_pengawas;
        fetchLoginGeneral(
            username,
            password,
            pperp,
            (error) => {
                console.log("error=", error);
            }).then((data) => {
                setTimeout(() => {

                    this.loggingInHandler(data, username, password, pperp)

                }, 1000)
            });
    }

    this.submitHandler = (event) => {
        event.preventDefault();
        this.loggingIn();
    };
    this.fetchExamInitial = (cb) => {
        fetchExamProfileInitial((error) => {
            console.error("error fetch exam", error)
            cb(false)
        }).then((data) => {
            if (data !== null) {
                data = data.result
                let ep = {
                    // date: data.date,
                    // startTime: data.start_time,
                    title: data.title,
                    title_2: data["title-2"],
                    start_time_ts: data.start_time_ts,
                    preparationTime: data.preparation,
                    preparation_time_ts: data.preparation_time_ts,
                    duration: data.duration,
                    end_time_ts: data.end_time_ts,
                    primary_logo_url: data.primary_logo_url,
                    hide_terms_conditions: data.hide_terms_conditions,
                }
                document.title = ep.title;

                const preparationTime = `${formatTime(convertEpochTime(data.preparation_time_ts).getHours())}:${formatTime(convertEpochTime(data.preparation_time_ts).getMinutes())}`;

                const startTime = `${formatTime(convertEpochTime(data.start_time_ts).getHours())}:${formatTime(convertEpochTime(data.start_time_ts).getMinutes())}`;

                const endTime = `${formatTime(convertEpochTime(data.end_time_ts).getHours())}:${formatTime(convertEpochTime(data.end_time_ts).getMinutes())}`;

                let et = {
                    preparationTime: preparationTime,
                    startTime: startTime,
                    endTime: endTime,
                }
                _this.setState({
                    examProfile: ep,
                    examTimes: et
                });
                cb(true, ep, et)
            } else {
                cb(false)
            }
        })
    }

    this.fetchExam = (cb) => {
        fetchExamProfile((error) => {
            console.log("error fetch exam", error);
            cb(false)
        }).then((data) => {
            if (data !== null) {
                data = data.result;
                let ep = {
                    // date: data.date,
                    // startTime: data.start_time,
                    title: data.title,
                    title_2: data["title-2"],
                    start_time_ts: data.start_time_ts,
                    preparationTime: data.preparation,
                    preparation_time_ts: data.preparation_time_ts,
                    duration: data.duration,
                    end_time_ts: data.end_time_ts,
                    primary_logo_url: data.primary_logo_url,
                    secondary_logo_url: data.secondary_logo_url,
                    useExtension: data.use_extension === 1,
                    peserta_per_pengawas: data.peserta_per_pengawas,
                    hide_terms_conditions: data.hide_terms_conditions,
                    audio_sensitivity: data.audio_sensitivity
                }

                document.title = ep.title;

                const preparationTime = `${formatTime(convertEpochTime(data.preparation_time_ts).getHours())}:${formatTime(convertEpochTime(data.preparation_time_ts).getMinutes())}`;

                const startTime = `${formatTime(convertEpochTime(data.start_time_ts).getHours())}:${formatTime(convertEpochTime(data.start_time_ts).getMinutes())}`;

                const endTime = `${formatTime(convertEpochTime(data.end_time_ts).getHours())}:${formatTime(convertEpochTime(data.end_time_ts).getMinutes())}`;

                let et = {
                    preparationTime: preparationTime,
                    startTime: startTime,
                    endTime: endTime,
                }
                _this.setState({
                    examProfile: ep,
                    examTimes: et
                });
                cb(true, ep, et)
            } else {
                // alert("Error to fetch exam profile");
                cb(false);
            }
        })
    }

    this.directUserLogin = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const exam_token = urlParams.get('exam_token');

        if (token) {
            console.log("TOKEN USING URL PARAMS")
            this.proceedWithToken(token, exam_token);
        } else {
            try {
                const receiveMessage = (event) => {

                    // Periksa asal pesan untuk keamanan
                    if (!event.origin) return;
                    const message = event.data;

                    if (message?.amaninToken) {
                        console.log("TOKEN USING COMMUNICATION BETWEEN PARENT AND IFRAME")
                        this.proceedWithToken(message.amaninToken, exam_token);
                    }
                }
                // Tambahkan event listener untuk menerima pesan
                window.addEventListener('message', receiveMessage, false);
            } catch (error) {
                console.error(error)
            }
        }
    }

    this.proceedWithToken = (token, exam_token) => {
        console.log("TOKEN IFRAME : ", token)
        fetchDirectUserLogin({
            token: token
        }, (error) => {
            console.error("[error] fetch direct user login", error);
        }).then((data) => {
            if (data.status === "success") {
                const expiresIn = 3600 * 12;
                const expirationTime = new Date(
                    new Date().getTime() + expiresIn * 1000
                );
                const result = data.result;
                _this.context.login(
                    "participant",
                    result.access_token,
                    result.id,
                    result,
                    "lang",
                    expirationTime
                );

                if (exam_token) {
                    console.log("exam_token : ", exam_token)
                    localStorage.setItem(`exam_token_${result.id}`, exam_token);
                }
                localStorage.setItem(`iframe_token_${result.id}`, token);

                this.redirectOnLogin(result);
            } else {
                ErrorAlert(data.errorMessage);
                window.parent.postMessage('accessFailed', '*');
                // window.parent.postMessage('accessFailed', telkomPageURL);
            }
        })
    }

}