import React from "react";
import classes from "./ParticipantVideo.module.scss";
import { TrimText, getPhotoFromLocal, urlLiveScreen, urlWebCam } from "../libs/proctor_utils";
import mail from "../assets/imgs/mail.png";
import newmessage from "../assets/imgs/newmessage.png";
import panic from "../assets/imgs/anxiety.png";
import anxiety from "../assets/imgs/pasrah.png";
import happy from "../assets/imgs/happy.png";
import livevideo from "../assets/imgs/livevideo.png";
import { LANG, validationValueColor } from "../libs/utils";
import PropTypes from 'prop-types';
import { Lang } from "../libs/Language";
import audio_detected from "../assets/imgs/audio_detected.png";

class ParticipantVideo extends React.Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();

    this.loadImageInterval = null;
    this.imageUpdateCounter = 0;

    this.imgFaceRef = React.createRef();
    this.imgScreenRef = React.createRef();

    this.state = {
      imgUrl: null,
      scrUrl: null
    };
  }

  componentDidMount() {
    this.setState({
      imgUrl: urlWebCam(this.props.participantId, this.props.folderRand, Date.now()),
      scrUrl: urlLiveScreen(this.props.participantId, this.props.folderRand, Date.now())
    })
    const COUNTER_STEP = 4;
    const STEP = 3;
    const MODULUS = COUNTER_STEP * STEP;
    // const DELAY = 3000;

    // fileapi sangat terbebani dengan read file dari storage, jika dipakai ribuan
    // get image per 1 menit untuk lebih leluasa
    // di masa depan, masukkan ke dalam konfigurasi,
    // untuk SM ITB 2024, load setiap 2 menit sekali
    const DELAY = 60000 / COUNTER_STEP
    // const DELAY = 20000 / COUNTER_STEP

    // delay = DELAY * COUNTER_STEP / 1000 = delay
    // delay = 3000 * 4 = 12000 milisecond = 12 second -> di update per 12 detik
    clearInterval(this.loadImageInterval);
    this.loadImageInterval = setInterval(() => {

      if (this.props.status === "offline") {
        return;
      }

      if (this.props.isParticipantModalOpen === true) {
        return;
      }
      // let a = convertStringIdToNumberId(this.props.participantId) % MODULUS;
      let a = this.props.seq % MODULUS;
      let b = this.imageUpdateCounter % COUNTER_STEP;
      if (a >= b * STEP && a < b * STEP + STEP) {
        if (document.getElementById("img_" + this.props.proctorId).style.display !== "none") {
          // console.log("load id=", this.props.seq, "=", a, this.props.participantId);
          this.setState({
            imgUrl: urlWebCam(this.props.participantId, this.props.folderRand, Date.now()),
            scrUrl: urlLiveScreen(this.props.participantId, this.props.folderRand, Date.now())
          })
        } else {
          this.setState({
            scrUrl: urlLiveScreen(this.props.participantId, this.props.folderRand, Date.now())
          })
        }
      }
      this.imageUpdateCounter++;
    }, DELAY);
  }

  componentWillUnmount() {
    clearInterval(this.loadImageInterval);
  }

  calculateEmotionImageHeight() {
    return 17 + Math.ceil(4 * (this.props.scale > 5 ? 5 : this.props.scale))
  }

  getWarningMessage() {
    let warningMessage = "unknown";
    if (this.props.aiwarning === 0) {
      warningMessage = Lang("Wajah tidak terdeteksi.", LANG(), { en: `Face not detected.` })
    } else if (this.props.aiwarning === 1) {
      warningMessage = Lang("Terdapat lebih dari 1 wajah.", LANG(), { en: `There are more than 1 face.` })
    } else if (this.props.aiwarning === 2) {
      warningMessage = Lang("Wajah tidak sesuai dengan kartu identitas.", LANG(), { en: `The face does not match the id card.` })
    } else if (this.props.aiwarning === 3) {
      warningMessage = Lang("Ada tab atau app lain dibuka.", LANG(), { en: `There is another tab or app open.` })
    } else if (this.props.aiwarning === 4) {
      warningMessage = Lang("Melakukan Print Screen atau Screenshot.", LANG(), { en: `Performing Print Screen or Screenshot.` })
    }
    return warningMessage;
  }

  renderMultiInformation() {
    let vel = null;
    if (this.props.dynvalidation.value !== "") {
      let w;
      if (this.props.dynvalidation.confirmed !== "1") {
        w = "17px";
      } else {
        w = "7px";
      }
      vel = <div
        className={[
          classes.container_v_validation
        ].join(" ")}
        style={{
          backgroundColor: validationValueColor(
            this.props.dynvalidation.green,
            this.props.dynvalidation.yellow,
            this.props.dynvalidation.orange,
            this.props.dynvalidation.pink,
            this.props.dynvalidation.red,
            this.props.dynvalidation.value,
          ),
          width: w,
          height: w
        }}
        title={`${Lang('tingkat kemiripan', LANG(), { en: 'similarity level' })} ${((1 - this.props.dynvalidation.value) * 100).toFixed(2)}%`}
      >
        {/* need confirmation */}
      </div>
    }
    return <>
      {vel}
      {this.props.liveVideoAvailable && <img
        alt="livevideo"
        className={[classes.container_v_livevideo, classes.FadeInPage1s].join(" ")}
        src={livevideo}
      />
      }
      {this.props.soundDetected && <div className={classes.container_v_hear}>
        <img alt="audio" src={audio_detected} width={"20px"} />
      </div>
      }
    </>

  }

  render() {
    let TW = 1;

    if (this.props.showID === true) {
      TW++;
    }
    if (this.props.showScreen === true) {
      TW++;
    }

    let SW = 70;
    if (this.props.showEmotion === false || (this.props.emotion && this.props.emotion.calm === -1)) {
      SW = 0;
    }

    let width = 110;
    width = width * this.props.scale;
    let warningMessage = this.getWarningMessage();

    const valid = {
      background: "white",
      color: "black",
      textAlign: "center",
    }
    const invalid = {
      background: "#3c14dc",
      color: "white",
      textAlign: "center",
    }
    const valid_without_device = {
      background: "#9c14ec",
      color: "white",
      textAlign: "center",
    }
    const colorValidation = this.props.validation === "valid" ? valid : this.props.validation === "valid_without_device" ? valid_without_device : invalid;
    const emotionLength = this.props.showEmotion ? 15 : 0;

    return (
      <div className={classes.container}
        title={this.props.participantId + "-" + this.props.participantName}
        style={{
          width: (width * TW + SW) + "px",
          height: (width * 0.8 + 50) + "px"
        }}>
        <div className={classes.container_v}
          style={{
            width: (width * TW + SW) + "px",
            height: (width * 0.8) + "px",
          }}>
          {
            this.props.newMessage && this.props.newMessage === true &&
            <div className={[classes.container_v_msg, classes.FadeInPage1s].join(" ")}>
              <img
                alt="chat"
                src={newmessage}
              />
            </div>
          }
          {this.renderMultiInformation()}
          {this.props.showID && <div className={classes.img}
            title={this.props.participantId + "-" + this.props.participantName}

            style={{
              width: width + "px",
              height: (width * 0.8) + "px"
            }}
          >
            <img
              alt="id"
              src={getPhotoFromLocal(this.props.photoUrl)}
              style={{
                maxWidth: width + "px",
                maxHeight: (width * 0.8) + "px"
              }}
            />
          </div>}

          <video
            title={this.props.participantId + "-" + this.props.participantName}
            muted={true}
            autoPlay={true}
            ref={this.videoRef}
            id={this.props.proctorId}
            style={{
              maxWidth: width + "px",
              maxHeight: (width * 0.8) + "px"
            }}
          >
          </video>
          <div
            title={this.props.participantId + "-" + this.props.participantName}
            className={classes.img}
            id={"img_" + this.props.proctorId}
            style={{
              width: width + "px",
              height: (width * 0.8) + "px"
            }}
          >
            <img
              alt="head"
              className={classes.transition}
              ref={this.imgFaceRef}
              src={this.state.imgUrl}
              style={{
                maxWidth: width + "px",
                maxHeight: (width * 0.8) + "px"
              }}
              onError={() => {
                setTimeout(() => {
                  this.imgFaceRef.current.src = this.state.imgUrl;
                }, 1000);
              }}
            />
          </div>

          {this.props.showScreen && <div className={classes.img}
            style={{
              width: width + "px",
              height: (width * 0.8) + "px"
            }}
          >
            <img
              alt="id"
              src={this.state.scrUrl}
              ref={this.imgScreenRef}
              style={{
                maxWidth: width + "px",
                maxHeight: (width * 0.8) + "px"
              }}
              onError={() => {
                this.imgScreenRef.current.src = this.state.scrUrl;
              }}
            />
          </div>}

          <div
            id={'status_' + this.props.proctorId}
            style={{
              width: width + "px",
              height: (width * 0.8) + "px",
            }}
            className={classes.status}>
            <div>offline</div>
          </div>

          {SW !== 0 && <div
            className={classes.stats}
            style={{ width: `${SW}px` }}
          >

            {
              (this.props.emotion && this.props.emotion.calm !== -1) && <div>
                <div title="tenang">
                  <img alt="happy" src={happy} height={`${this.calculateEmotionImageHeight()}px`} />
                  <span>
                    {this.props.emotion ? this.props.emotion.calm : "00"}%
                  </span>
                </div>
                <div title="gelisah">
                  <img alt="anxiety" src={anxiety} height={`${this.calculateEmotionImageHeight()}px`} />
                  <span>
                    {this.props.emotion ? this.props.emotion.anxiety : "00"}%
                  </span>
                </div>
                <div title="panik">
                  <img alt="panik" src={panic} height={`${this.calculateEmotionImageHeight()}px`} />
                  <span>
                    {this.props.emotion ? this.props.emotion.pasrah : "00"}%
                  </span>
                </div>
              </div>
            }
          </div>
          }
        </div>

        <div className={[
          classes.container_t,
          this.props.aiwarning !== -1 ?
            classes.warning : null].join(" ")
        }>
          <div>
            <span
              className={classes.container_t_i}
              style={colorValidation}
              title={this.props.participantId + "-" + this.props.participantName}
            >
              {TrimText(this.props.participantId, TW === 1 ? this.props.scale * 16 + emotionLength : this.props.scale * (TW * 16) + emotionLength)}
            </span>
            <span
              className={classes.container_t_n}
              title={this.props.participantId + "-" + this.props.participantName}
            >
              {TrimText(this.props.participantName, TW === 1 ? this.props.scale * 16 + emotionLength : this.props.scale * (TW * 16) + emotionLength)}
            </span>
          </div>
          {
            this.props.aiwarning === -1 ?
              null :
              <span className={classes.container_t_w}
                title={warningMessage}
              >
                <span>!</span>
                {TrimText(warningMessage, TW === 1 ? this.props.scale * 16 + emotionLength : this.props.scale * (TW * 16) + emotionLength)}
              </span>
          }
        </div>
      </div>
    );
  }
}

ParticipantVideo.propTypes = {
  participantId: PropTypes.string.isRequired,
  folderRand: PropTypes.string.isRequired,
  isParticipantModalOpen: PropTypes.bool.isRequired,
  seq: PropTypes.number.isRequired,
  proctorId: PropTypes.string.isRequired,
  scale: PropTypes.number.isRequired,
  showID: PropTypes.bool.isRequired,
  showScreen: PropTypes.bool.isRequired,
  showEmotion: PropTypes.bool.isRequired,
  emotion: PropTypes.object.isRequired,
  aiwarning: PropTypes.number.isRequired,
  validation: PropTypes.string.isRequired,
  participantName: PropTypes.string.isRequired,
  newMessage: PropTypes.bool.isRequired,
  dynvalidation: PropTypes.object.isRequired,
  photoUrl: PropTypes.string.isRequired,
  status: PropTypes.string,
  soundDetected: PropTypes.bool.isRequired
}

export default ParticipantVideo;